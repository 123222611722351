<template>
  <div>
    <a-spin :spinning="loading">
      <div class="mendian-page-header" style="height: 60px;text-align: center;background: #eff6fb;">
        <div class="main flex alcenter space">
          <div class="flex alcenter" style="margin-left: 15px;">
            <img v-if="account.face != null" style="width: 60px; height: 60px;" :src="account.face" />
            <img v-else style="width: 60px; height: 60px;" src="../assets/image/woyaoyou_head_defult@2x.png" />
          </div>
          <div class="flex alcenter">
            <span class="ft14 cl-w mr10" style="margin-left: 20px;color: #000;">你好，{{ account.username }}</span>
            <a-button @click="showPwd = true">修改密码</a-button>
            <a-button @click="loginOut()" style="margin-left: 10px;margin-right: 10px;">退出</a-button>
          </div>
        </div>
      </div>
      <div class="mendian-page-header" style="background: #F9F9F9;">
        <div class="main" style="background: white;">
          <a-card style="border: 0;background: #F9F9F9;">
            <div class=" flex alcenter">
              <span class="ft20 ftw600 cl-main">管理中心({{ mendians.length }})</span>
              <span class="ft14 cl-notice ml10" style="color: darkgray;">每个平台为独立运营的，会员是不通用的。每个平台可以邀请管理人一起管理</span>
            </div>
            <div class="mt30 mendian-list">
              <div style="background: rgb(255, 255, 255);" v-for="item in mendians" @click="manageAct(item)"
                :class="{ dis: item.shop == null }" class="mendian-item flex space">
                <div>
                  <div class="flex alcenter">
                    <div class="ft16 ftw600 cl-main text-over4">
                      {{ item.shop == null ? "该门店已注销" : '【' + item.shop.shop_id + '】' + item.shop.name }}
                    </div>
                    <div class="ft14 ftw400 cl-notice ml5 text-over4">
                      ({{
                        item.shop == null
                        ? ""
                        : item.shop.application == null
                          ? ""
                          : item.shop.application.name
                      }})
                    </div>
                  </div>
                  <div style="margin-top: 10px;">
                    <div style="margin-left: 5px;">开通时间：{{ item.add_time_format }} </div>
                  </div>
                </div>
                <div v-if="item.shop.isdb == 1" class="red_tag">数据源</div>
                <div class="tag" v-if="item.role_id == 1">创始人</div>
                <div class="tag" v-if="item.role_id == 2">管理员</div>
              </div>
              <!-- 创始人才能创建商铺  item.role_id == 1 -->
              <div @click="addMendian" class="mendian-item add flex alcenter center"
                style="background: rgb(255, 255, 255);">
                <i class="iconfont iconadd_big cl-theme"></i>
                <span class="ft16 cl-theme ftw600 ml10">创建商铺</span>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </a-spin>

    <a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
      <template slot="footer">
        <a-button key="back" @click="handlePwdCancel"> 取消 </a-button>
        <a-button key="submit" type="primary" :loading="pwdloading" @click="handlePwdOk">
          确认
        </a-button>
      </template>
      <a-form-model :model="pwd" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="输入密码">
          <a-input type="password" v-model="pwd.pwd" />
        </a-form-model-item>
        <a-form-model-item label="再次确认">
          <a-input type="password" v-model="pwd.repwd" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      showPwd: false,
      isRoot: false,
      pwd: {
        pwd: "",
        repwd: "",
      },
      pwdloading: false,
      account: {
        mobile: "",
        username: "",
        face: null,
      },
      mendians: [],
    };
  },

  created() {
    this.getLists();
  },
  methods: {
    handlePwdCancel() {
      this.showPwd = false;
    },
    handlePwdOk() {
      if (this.pwdloading == true) return;
      this.pwdloading = true;
      this.$http
        .api("platform/account/editPwd", {
          pwd: this.pwd.pwd,
          repwd: this.pwd.repwd,
        })
        .then((res) => {
          this.pwdloading = false;
          this.$message.success("密码设置成功");
          this.showPwd = false;
        })
        .catch((res) => {
          this.pwdloading = false;
        });
    },
    getLists() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/home")
        .then((res) => {
          //console.log(JSON.stringify(res))
          this.account = res.account;
          this.mendians = res.mendian;

          if (this.mendians.length > 0) {
            this.isRoot = this.mendians[0].role_id == 1
          } else {
            this.isRoot = true
          }

          if (this.account.password == null || this.account.password == "") {
            this.showPwd = true;
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    manageAct(item) {
      if (item.shop == null) {
        this.$message.error("该门店已注销");
        return false;
      }

      let base_url = item.shop.application.development_address;
      //let base_url= 'http://localhost:9190/beautiful/#/'

      window.open(
        base_url + "auth?shop_token=" + encodeURIComponent(item.shop_token)
      );
    },
    handleOk() {
      this.showForm = false;
    },
    handleCancel() {
      this.showForm = false;
    },
    addMendian() {
      if (this.isRoot) {
        this.$router.push("/mendian/create");
      } else {
        this.$message.error("你不是创始人，没有权限！");
      }
    },
    loginOut() {
      localStorage.removeItem('account-token');
      this.$router.push('/');
    }
  },
};
</script>

<style>
.mendian-list {
  display: grid;
  grid-template-columns: repeat(3, 380px);
  grid-template-rows: repeat(3, 100px);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.mendian-item {
  width: 380px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border-left: 8px solid #ebedf5;
  padding: 24px 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.mendian-item.dis {
  opacity: 0.5;
}

.mendian-item .tag {
  width: 52px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #4772ff;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  color: #4772ff;
}

.mendian-item .red_tag {
  width: 52px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #ff0000;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  color: #ff0000;
  font-weight: 800;
}

.mendian-item:hover {
  transform: translateY(-10px);
}

.mendian-item.add {
  border: 1px dashed #4772ff;
}

.mendian-footer {
  flex: 0 0 auto;
  background-color: #eaa53a;
  height: 50px;
  color: white;
  text-align: center;
}

.mendian-page-header {
  width: 100%;
  height: 60px;
  background: #3586B8;
  flex: 0 0 auto;
}

.mendian-page-header .main {
  width: 1200px;
  height: 60px;
  margin: 0 auto;
}

.mendian-page-header .log-out {
  width: 44px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
}

.mendian-page-header .title {
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  padding-left: 12px;
  border-left: 1px solid #FFFFFF;
  margin-left: 12px;
  height: 20px;
  line-height: 20px;
}

.mendian-page-main {
  margin-top: 10px;

}
</style>